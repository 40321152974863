import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useQuery } from '@tanstack/react-query';

import Page from 'components/layouts/page/Page';
import FilterChips from 'components/shared/FilterChips';
import FilterBar from 'components/shared/filters/FilterBar';
import DesktopOnly from 'components/shared/responsiveness/DesktopOnly';
import ControlledTable from 'components/shared/table/ControlledTable';
import { useProfile } from 'context/profile';
import useIsMobile from 'hooks/useIsMobile';
import usePagination from 'hooks/usePagination';
import { indexAllPatients } from 'services/api/locationEpisode/allPatients';
import { serializeSort, useAllPatientsActions, useAllPatientsStore } from 'stores/patientFiltersStore';
import { BodySmall } from 'styles/typography';

import { getFilterSections } from './helpers/filters';
import allPatientsTableColumns from './allPatientsTableColumns';

// Order does matter here. This dictates the joins in the backend
// query and it was slower when ordered differently.
const INCLUDES = [
  'episode',
  'current_rehab_state',
  'hospital',
  'patient',
  'episode_classifications',
  'plan_type_classification',
  'owner',
  'physician_team',
  'rehab_information',
];

function AllPatients() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { profile } = useProfile();
  const pagination = usePagination();
  const patientCount = useRef(0);
  const filters = useAllPatientsStore((state) => state.filters);
  const sort = useAllPatientsStore((state) => state.sort);
  const search = useAllPatientsStore((state) => state.search);
  const { removeFilter, setFilters, setSort, setSearch } = useAllPatientsActions();

  useEffect(() => () => setSearch(''), [setSearch]);

  const queryParams = useMemo(
    () => ({
      ...pagination.paging,
      sortBy: serializeSort(sort),
      search: search,
      filters,
      include: INCLUDES.join(','),
    }),
    [search, filters, pagination.paging, sort]
  );

  useEffect(() => {
    pagination.setPageIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filters]);

  const { data: patients, isLoading } = useQuery({
    queryKey: ['allPatients', { ...queryParams }],
    queryFn: ({ signal }) => indexAllPatients(queryParams, signal),
    select: (data) => {
      patientCount.current = data.meta.totalRecords;
      return data;
    },
  });

  const columns: any = useMemo(
    () =>
      allPatientsTableColumns({
        profile: profile,
        onClickPatient: (id: string) => navigate(`/patients/${id}`),
      }),
    [navigate, profile]
  );

  const handleRemoveFilter = useCallback(
    (filterKey: string, id: string) => {
      removeFilter(filterKey, id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Case Manager and Utilization Manager filters should be shown if the client
  // has them enabled on any one of their enabled group types.
  const caseManagerEnabled = !!profile.actingClient?.clientGroupTypes.some(
    (clientGroupType) => clientGroupType.config.caseManager
  );
  const utliizationManagerEnabled = !!profile.actingClient?.clientGroupTypes.some(
    (clientGroupType) => clientGroupType.config.utilizationManager
  );

  const filterSections = useMemo(
    () =>
      getFilterSections(
        profile.actingClient.clientType,
        profile.enabledProviderTypes ?? [],
        caseManagerEnabled,
        utliizationManagerEnabled,
        profile.canActAsProvider
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profile.actingClient.clientType, profile.enabledProviderTypes, profile.canActAsProvider]
  );

  const handleApplyFilters = useCallback(
    ({ search, ...filters }) => {
      setFilters(filters);
      setSearch(search);

      if (search) {
        setSort([{ id: 'search', desc: true }]);
      } else {
        setSort([{ id: 'patientName', desc: false }]);
      }
    },
    [setFilters, setSort, setSearch]
  );

  return (
    <Page>
      <FilterBar
        applyFilters={handleApplyFilters}
        filters={filters}
        filterSections={filterSections}
        queryFn={indexAllPatients}
        onClearFilters={() => setFilters({})}
        title='All Patients'
      />
      <StyledPageSubHeader>
        <DesktopOnly>
          <FilterChips filters={filters} onRemoveFilter={handleRemoveFilter} />
        </DesktopOnly>
        <BodySmall>{patientCount.current} patients found</BodySmall>
      </StyledPageSubHeader>
      <Page.Content>
        <ControlledTable
          data={patients?.data ?? []}
          filters={filters}
          columns={columns}
          loading={isLoading}
          pageCount={patients?.meta.totalPages ?? 1}
          pagination={pagination}
          sorting={{ sortBy: sort, setSortBy: setSort }}
          state={{
            columnVisibility: {
              ownerName: !isMobile,
              hospitalName: !isMobile,
              rehabFacility: !isMobile,
              physicianTeamName: !isMobile,
              planTypeClassification: !isMobile,
              episodeClassifications: !isMobile,
              admittedOn: !isMobile,
              currentRehabState: !isMobile,
              lengthOfStay: !isMobile,
            },
          }}
        />
      </Page.Content>
    </Page>
  );
}

export default AllPatients;

const StyledPageSubHeader = styled(Page.SubHeader)`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${({ theme }) => theme.devices.desktop} {
    gap: 18px;
  }
`;
