import {
  ADDITIONAL_FILTERS,
  COMANAGEMENT_TEAM,
  createProviderDropdown,
  DISCHARGE_REASON_DROPDOWN,
  EPISODE_TYPE_DROPDOWN,
  FilterDropdown,
  getClientFilterDropdown,
  getComanagementTeamManagerFilterDropdowns,
  LATEST_REHAB_STATE_DROPDOWN,
  PLAN_TYPE_DROPDOWN,
} from 'constants/filterConfigs';
import { END_DATE, START_DATE } from 'constants/filterKeysConstants';
import { ClientType } from 'models/Client';
import GroupType from 'models/GroupType';
import { InsightsFilterState } from 'stores/insightsStore';

const rehabStateDropdown = {
  ...LATEST_REHAB_STATE_DROPDOWN,
  name: 'rehabState',
};

export const getFilterSections = (selectedGroupType: GroupType, actingClientType: ClientType) => {
  return [
    {
      title: COMANAGEMENT_TEAM,
      filters: [
        ...getClientFilterDropdown(actingClientType, selectedGroupType),
        createProviderDropdown(selectedGroupType),
        ...getComanagementTeamManagerFilterDropdowns(actingClientType),
      ],
    },
    {
      title: ADDITIONAL_FILTERS,
      filters: [PLAN_TYPE_DROPDOWN, EPISODE_TYPE_DROPDOWN, rehabStateDropdown, DISCHARGE_REASON_DROPDOWN],
    },
  ];
};

const staticFilterKeys = (selectedGroupType: GroupType, actingClientType: ClientType) => {
  return [
    ...getComanagementTeamManagerFilterDropdowns(actingClientType),
    ...(getFilterSections(selectedGroupType, actingClientType).find((section) => section.title === ADDITIONAL_FILTERS)
      ?.filters || []),
  ].reduce((acc: string[], filterDropdown: FilterDropdown) => {
    return [...acc, filterDropdown.name];
  }, []);
};

const filterKeysForSelectedGroupType = (selectedGroupType: GroupType, actingClientType: ClientType) => {
  const companyFilterKey = `${
    [ClientType.INPATIENT_POST_ACUTE_CARE, ClientType.OUTPATIENT_POST_ACUTE_CARE].includes(actingClientType)
      ? 'owner'
      : selectedGroupType?.camelCaseApiName
  }Client`;

  return [
    ...staticFilterKeys(selectedGroupType, actingClientType),
    selectedGroupType?.camelCaseApiName,
    companyFilterKey,
    START_DATE,
    END_DATE,
  ];
};

export const filtersForSelectedGroupType = (
  filters: InsightsFilterState,
  selectedGroupType: GroupType,
  actingClientType: ClientType
) => {
  return Object.entries(filters).reduce(
    (acc, [key, val]) =>
      filterKeysForSelectedGroupType(selectedGroupType, actingClientType).includes(key) ? { ...acc, [key]: val } : acc,
    {}
  );
};

export const getFiltersForSelectedGroupType = (
  filters: InsightsFilterState,
  selectedGroupType: GroupType,
  actingClientType: ClientType
) => {
  return [
    filtersForSelectedGroupType(filters, selectedGroupType, actingClientType),
    getFilterSections(selectedGroupType, actingClientType),
  ];
};
